



































import Vue from "vue";
import APP_CONFIG from "@/apps/core/modules/config";

const urlBase = `${APP_CONFIG.baseS3Url}/eli/bantuan/`;
const pdfMap = new Map<string, string>([
  ["residen", `${urlBase}logbook_residen.pdf`],
  ["konsulen", `${urlBase}logbook_konsulen.pdf`],
  ["admin", `${urlBase}logbook_admin.pdf`],
]);

export default Vue.extend({
  methods: {
    openNewTab(group: string) {
      window.open(pdfMap.get(group), "_blank");
    },
  },
});
